<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 예산년도 -->
          <c-datepicker
            label="예산년월"
            name="year"
            type="month"
            :range="true"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-select
            type="search"
            :comboItems="budgetCatItems"
            itemText="codeName"
            itemValue="code"
            label="카테고리"
            name="budgetCat"
            v-model="searchParam.budgetCat">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="KOSTL_CD"
            itemText="codeName"
            itemValue="code"
            name="kostl"
            label="Cost Center"
            v-model="searchParam.kostl"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="KSTAR_CD"
            itemText="codeName"
            itemValue="code"
            name="kstar"
            label="계정"
            v-model="searchParam.kstar"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-select
            type="search"
            :comboItems="actFlagItems"
            itemText="codeName"
            itemValue="code"
            label="예실여부"
            name="actFlag"
            v-model="searchParam.actFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="적요"
            name="description"
            v-model="searchParam.description">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-select
            type="search"
            codeGroupCd="ZCOMAT0008_CD"
            itemText="codeName"
            itemValue="code"
            name="zcomat0008Cd"
            label="업무구분"
            v-model="searchParam.zcomat0008Cd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 연간 예산편성 목록 -->
    <c-table
      ref="table"
      title="연간 예산실적관리"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :expandAll="true"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="saveData"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveMaster"
            @btnCallback="saveMasterCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customBottomRow>
        <q-tr 
          v-if="grid.data && grid.data.length > 0" 
          no-hover
          class="table-bottom-fixed">
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">합계
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.sumBy(grid.data, 'amt') | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-health-year-budget',
  data() {
    return {
      searchParam: {
        plantCd: '',
        years: [],
        budgetCat: null,
        kostl: null,
        kstar: null,
        actFlag: null,
        zcomat0008Cd: null,
        description: '',
      },
      budgetCatItems: [
        { code: '1', codeName: '일반예산' },
        { code: '2', codeName: '투자예산' },
      ],
      actFlagItems: [
        { code: 'P', codeName: '예산' },
        { code: 'A', codeName: '실적' },
      ],
      grid: {
        columns: [
          {
            name: 'zdate',
            field: 'zdate',
            label: '예산년월',
            style: 'width:90px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'budgetCatName',
            field: 'budgetCatName',
            label: '예산카테고리',
            style: 'width:90px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'kostlNm',
            field: 'kostlNm',
            label: 'Cost Center',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'kstarNm',
            field: 'kstarNm',
            label: '계정',
            style: 'width:300px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'actFlagName',
            field: 'actFlagName',
            label: '예실여부',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'aufnr',
            field: 'aufnr',
            label: '오더번호',
            style: 'width:90px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'aufnrNm',
            field: 'aufnrNm',
            label: '오더이름',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'description',
            field: 'description',
            label: '적요',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'amt',
            field: 'amt',
            label: '금액',
            style: 'width:140px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'zcomat0008Cd',
            field: 'zcomat0008Cd',
            label: '업무구분',
            style: 'width:110px',
            align: 'center',
            sortable: false,
            codeGroupCd: 'ZCOMAT0008_CD',
            type: 'select',
            setHeader: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      isSave: false,
      saveUrl: '',
      listUrl: '',
      saveData: [],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.budget.year.list.url;
      this.saveUrl = transactionConfig.sai.budget.year.update.url;
      this.searchParam.years = [this.$comm.getThisYear() + '-01', this.$comm.getThisYear() + '-12'] 
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "LBL0010323"; // 연간 예산편성 상세
      this.popupOptions.param = {
        budgetYearPlanId: row ? row.budgetYearPlanId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./budgetYearPlanDetail.vue"}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    saveMaster() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        this.saveData = this.grid.data.filter(item => {
          return item.editFlag === 'U' 
        });
        if(this.saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까? 
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다.
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveMasterCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    }
  }
};
</script>
